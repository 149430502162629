.frame {
    width: 70%;
    margin: 40px auto;
    text-align: center;
}

/* button {
    margin: 20px;
}

a {
    margin: 20px;
} */

.my-b {
    appearance: none;
    background-color: transparent;
    border: 2px solid #1A1A1A;
    border-radius: 15px;
    box-sizing: border-box;
    color: #3B3B3B;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0px;
    min-height: 50px;
    min-width: 60px;
    max-width: 180px;
    outline: none;
    padding: 18px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    will-change: transform;
}

.my-b:disabled {
    pointer-events: none;
}

.my-b:hover {
    color: rgb(0, 0, 0);
    background-color: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    text-decoration: none;
}

.my-b:active {
    box-shadow: none;
    transform: translateY(0);
}

.sidebyside {
    float: left;
    padding: 0px;
}