html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-size: 16px;
}

.subtitle {
  text-align: center;
  font-style: italic;
}


.container {
  max-width: 30rem;
  padding: 0 1rem;
  margin: 3rem auto 6rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.float-container {
  border: 3px solid #fff;
  padding: 20px;
  max-width: 32rem;
  margin: 3rem auto 6rem;
}

.float-child {
  width: 50%;
  float: left;
  padding: 20px;
  border: 2px solid red;
}

.backToHome {
  margin: 3rem 0 0;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  display: block;
}

.heading2Xl {
  font-size: 2.2rem;
  line-height: 1.2;
  font-weight: 800;
  letter-spacing: -0.05rem;
  margin: 1rem 0 0 0;
}

.headingXl {
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 800;
  letter-spacing: -0.05rem;
  margin: 1rem 0 0 0;
}

.headingLg {
  font-size: 1.5rem;
  line-height: 1.4;
  margin: 1rem 0;
}

.headingMd {
  font-size: 1.1rem;
  margin-top: 0.4rem;
}

.borderCircle {
  border-radius: 9999px;
}

.colorInherit {
  color: inherit;
}

.padding1px {
  padding-top: 1px;
}