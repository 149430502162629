.socialContainer {
  width: 100%;
  text-align: center;
}

.socialButton {
  padding: 0 13px;
  display: inline-block;
  margin: 0 auto;
  font-size: 2.25em;
  border-radius: 50%;
}

.socialButton a {
  color: rgb(25, 117, 198);
}

.socialButton a:hover {
  text-decoration: none;
  opacity: 0.7;
}